html, body {
  overflow-x: hidden;
}

body {
  position: relative
}

body {
  margin: 0;
  color: white;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  background-image: url("purple-flower-background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 4.5rem;
}

.dropdown-menu {         
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
